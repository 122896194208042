<template>
  <div class="mymissiontabletable-container">
    <div class="mymissiontabletable-title">
      <div class="missioncount">全部<span>(95)</span></div>
      <div class="missionselect">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          v-model="input2"
          class="selectinput"
        >
        </el-input>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      class="mymissiontabletable"
      stripe
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="80"
        fixed
      >
      </el-table-column>

      <!-- 业务类型 -->
      <el-table-column prop="business_type" align="center" label="业务类型"></el-table-column>

      <!-- 公司名称 -->
      <el-table-column prop="corporate_name" align="center" label="公司名称"></el-table-column>

      <!-- 发布人 -->
      <el-table-column prop="publisher" align="center" label="发布人"> </el-table-column>
      <!-- 发布时间 -->
      <el-table-column prop="release_time" align="center" label="发布时间"> </el-table-column>
      <!-- 到期时间 -->
      <el-table-column prop="expiration_time" align="center" label="到期时间"> </el-table-column>
       <!-- 任务状态 -->
      <el-table-column prop="task_status" align="center" label="任务状态"> </el-table-column>
       <!-- 任务详细 -->
      <el-table-column prop="task_details" align="center" label="任务详细"> </el-table-column>
       <!-- 备注 -->
      <el-table-column prop="task_ps" align="center" label="备注"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button @click="open" type="button" class="confirmbtn"
          :disabled='scope.row.task_status=="进行中" '
            >完成</el-button
          >
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <editmenu-com
      :editmenuVisible="editmenuVisible"
      :rowdata="rowdata"
      @editmenuVisibleClose='editmenuVisibleClose'
    ></editmenu-com> -->
    <!-- <editfinancial
      :editfinancialVisible="editfinancialVisible"
      @editfinancialVisibleClose="editfinancialVisibleClose"
      :rowdata="rowdata"
    ></editfinancial> -->
    <pagination-com></pagination-com>
    <mymissiondetails-com
        :mymissiondetailsVisible='mymissiondetailsVisible'
        :rowdata='rowdata'
        @mymissiondetailsVisibleClose='mymissiondetailsVisibleClose'
    ></mymissiondetails-com>
  </div>
</template>


<script>
// import editmenuCom from './editmenuCom.vue'
// import editfinancial from './editfinancialCom.vue'
import paginationCom from '../paginationCom.vue'
import mymissiondetailsCom from './mymissiondetailsCom.vue'


export default {
  components: {
    // editmenuCom
    // editfinancial
    paginationCom,mymissiondetailsCom
  },
  data() {
    return {
      mymissiondetailsVisible: false,
      value: '',
      input2: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      
      rowdata: {},
      /**
       * 公司名称:corporate_name
       * 业务类型:business_type
       * 发布人:publisher
       * 发布时间:release_time
       * 到期时间:expiration_time
       * 任务状态:task_status
       * 任务详细:task_details
       * 备注:task_ps
       */
      //   最多7条数据可以展示在页面上  所以一页就是7条
      tableData: [{
       corporate_name:'广西天灿人力资源有限公司',
       business_type:'大厅申报',
       publisher:'天小餐',
       release_time:'2021-07-01 15:34:05',
       expiration_time:'2021-07-01 15:34:05',
       task_status:'已完成',
       task_details:'信息详细展示',
       task_ps:'备注展示'
      },
      {
       corporate_name:'广西天灿人力资源有限公司',
       business_type:'大厅申报',
       publisher:'天小餐',
       release_time:'2021-07-01 15:34:05',
       expiration_time:'2021-07-01 15:34:05',
       task_status:'进行中',
       task_details:'信息详细展示',
       task_ps:'备注展示'
      },
      ],
    }
  },
  props: {

  },
  methods: {
    handleClick(row) {
      console.log(row);
      this.rowdata = row
      this.mymissiondetailsVisible = true
    },
    mymissiondetailsVisibleClose() {
      this.mymissiondetailsVisible = false
    },
    filterTag(value, row) {
      return row.state === value;
    },
    open() {
      this.$message({
        message: '您已成功确认',
        type: 'success'
      });
    }
  }
}
</script>



<style lang="scss">
.mymissiontabletable-container {
//   height: 550px;
//   overflow: hidden;
    background-color: #ffffff;
    box-sizing: border-box;
    padding-bottom: 50px;
  .mymissiontabletable{
      height: 900px;
  }
  .el-table thead{
      color: #333333;
  }
  .is-disabled{
      background-color: #cccccc !important;
  }
  .mymissiontabletable-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px 10px 40px;
    background-color: $index-buttonPor-color;
    color: #ffffff;
    box-sizing: border-box;
    // height: 60px;
    
  }
  .missionselect {
    display: flex;
    .el-input__icon {
      line-height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    .selectinput {
      height: 30px;
      margin-left: 10px;
    }
  }
  .available_rolesspan {
    margin-left: 5px;
  }
  .available_rolesspan:first-child {
    margin-left: 0px;
  }
  .mymissiontabletable {
    // thead {
    //   th {
    //     // color: #ffffff;
    //     // background-color: $index-buttonPor-color;
    //   }
    // }

    .seebtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
    .confirmbtn {
      color: #ffffff;
      background-color: $index-button-color;
    }
    .withdrawbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
  }
}
</style>