<template>
  <div class="mymission-container">
    <el-tabs type="card">
      <el-tab-pane class="tabsItem">
        <span slot="label"
          ><i class="iconfont icon-quanbu"></i>全部
          <el-badge :value="alltodo" class="badgespan" v-if="alltodo>0"></el-badge>
        </span>
        <mymissiontable-com></mymissiontable-com>
      </el-tab-pane>
      <el-tab-pane >
          <span slot="label"
          ><i class="iconfont icon-dangqianrenwu"></i>当前任务
          <el-badge :value="nowtodo" class="badgespan" v-if="nowtodo>0"></el-badge>
        </span>
      </el-tab-pane>
      <el-tab-pane><span slot="label"
          ><i class="iconfont icon-yiwancheng"></i>已完成
          <el-badge :value="endtodo" class="badgespan" v-if="endtodo>0"></el-badge>
        </span></el-tab-pane>
      <el-tab-pane><span slot="label"
          ><i class="iconfont icon-tongji"></i>任务统计
          <el-badge :value="12" class="badgespan" v-if="value>0"></el-badge>
        </span></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import mymissiontableCom from '../components/missionmanagement/mymissiontable'

export default {
  components: {
    mymissiontableCom
  },
  data() {
    return {
      activeName: 'first',
      alltodo:0,
      nowtodo: 30,
      endtodo:0,
      value:'',
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style lang="scss">
.mymission-container {
  padding: 30px 50px;
  height: 100%;
  box-sizing: border-box;
  background-color: #f2f2f2;
  .el-select{
      .el-input__inner{
          background-color: $index-buttonPor-color;
          color: #ffffff;
      }
      .el-input__inner::placeholder {
        color: #fff;
        text-align:center;
    }
  }
  .iconfont{
      margin-right: 10px;
      font-size: 18px;
  }
  .el-tabs__header {
    margin: 0 !important;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
      border: none;
  }
  .el-tabs--card > .el-tabs__header {
    border: 0;
  }
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    padding: 0 40px;
    // text-align: center;
    font-size: 18px;
    color: #9b9b9b;
  }
  .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
    // padding-left: 40px;
  }
  .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
    // padding-right: 40px;
  }
  .is-active {
    background-color: $index-buttonPor-color !important;
    color: #ffffff !important;
  }
  .badgespan {
    position: absolute;
    top: -6px;
    right: 10px;
    .el-badge__content {
      height: 15px;
      line-height: 15px;
    }
  }
}
</style>